<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-btn color="primary" :loading="downloading" @click="downloadReport" :disabled="downloading">
      <v-icon left>mdi-download</v-icon>
      Download Report
    </v-btn>
    <v-card>
      <v-card-text>
        <VValidationErrors :validationErrors="validationErrors"></VValidationErrors>
      </v-card-text>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="5" lg="5">
            <v-menu
              v-model="fromMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formData.from"
                  label="From"
                  append-icon="mdi-inbox-arrow-up"
                  readonly
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                :max="new Date().toISOString().substr(0, 10)"
                v-model="formData.from"
                @input="fromMenu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="5" lg="5">
            <v-menu
              v-model="toMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formData.to"
                  label="To"
                  append-icon="mdi-inbox-arrow-down"
                  readonly
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                class="mt-0"
                :max="new Date().toISOString().substr(0, 10)"
                v-model="formData.to"
                @input="toMenu = false">
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="2">
            <div class="text-center">
              <v-btn color="primary" large dark @click="submit">Filter results</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>


    <v-row>



      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="warning"
          icon="mdi-doctor"
          title="Total Consultations"
          :value="reports.numberOfConsultations"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="primary"
          icon="mdi-medical-bag"
          title="Drugs"
          :value="reports.recommendation.drugs"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="info"
          icon="mdi-medical-bag"
          title="Commercial Drugs"
          :value="reports.recommendation.drugCommercials"
        />
      </v-col>


      <v-col cols="12" sm="6" lg="3">
        <base-material-stats-card
          color="error"
          icon="mdi-test-tube"
          title="Labs"
          :value="reports.recommendation.labs+reports.recommendation.panels"
        />
      </v-col>



    </v-row>

    <v-row>
      <v-col cols="12" lg="6" sm="12">
        <base-material-card class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Consultation Categories
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="dataTableHeader"
              :items="reports.consultationCategory"
              hide-default-footer
              disable-pagination
            />
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-col cols="12" lg="6" sm="12">
        <base-material-card class="px-5 py-3" color="warning">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Patient Categories
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="dataTableHeader"
              :items="reports.patientCategory"
              hide-default-footer
              disable-pagination
            />
          </v-card-text>
        </base-material-card>
      </v-col>


    </v-row>


  </v-container>
</template>

<script>
  import MaterialChartCard from "../../components/base/MaterialChartCard";
  import VValidationErrors from "../../components/base/VValidationErrors";

  export default {
    name: "Consultations",
    components: {VValidationErrors, MaterialChartCard},
    data() {
      return {
        downloading: false,
        formData: {
          from: '',
          to: '',
        },
        dataTableHeader: [
          {
            sortable: true,
            text: 'Name',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Count',
            value: 'count',
          },
        ],
        reports: {
          consultationCategory: [],
          patientCategory: [],
          numberOfConsultations:0,
          recommendation: {
            drugCommercials: 0,
            drugs: 0,
            labs: 0,
            panels: 0
          },
        },

        search: '',
        date: new Date().toISOString().substr(0, 10),
        fromMenu: false,
        toMenu: false,
        validationErrors: {},
      }
    },
    methods: {
      submit() {
        this.$authApi.get('consultations/report', {
          params: this.formData
        }).then(res => {
          this.reports = res.data.data;
          this.validationErrors = {};
          this.processData();
        }).catch(error => {
          console.log(error);
        })
      },
      processData() {
        this.reports.numberOfConsultations = parseInt(this.reports.numberOfConsultations);
        this.reports.recommendation.drugCommercials = parseInt(this.reports.recommendation.drugCommercials);
        this.reports.recommendation.drugs = parseInt(this.reports.recommendation.drugs);
        this.reports.recommendation.labs = parseInt(this.reports.recommendation.labs);
        this.reports.recommendation.panels = parseInt(this.reports.recommendation.panels);
      },
      downloadReport() {
        this.downloading = true;
        this.$downloadApi.get('consultations/export-report', {
          params: this.formData
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `consultation report (${this.formData.from} - ${this.formData.to}).xlsx`);
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        })
      },
    },
    created() {
      let d = new Date();
      let splitedDate = d.toISOString().split('T')[0].split('-');
      this.formData.from = splitedDate[0]+'-'+splitedDate[1]+'-01';
      this.formData.to = d.toISOString().split('T')[0];
      this.submit();
    },
  }
</script>

<style scoped>
  .full-width {
    width: 100%;
  }
</style>
